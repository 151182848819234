import React from 'react';

import { AuthFields } from '../../../../authTypes';

import { useSignInForm } from './hooks/useSignInForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/forms/formFields/InputField';
import { PureSubmitButtonHelper } from '../../../../../helpers/buttons/PureSubmitButtonHelper';

import { OnSignInUserForm } from './SignInForm.types';

interface SignInPageProps {
  onSignIn: OnSignInUserForm;
  callbackUrl?: string;
  emailLabel?: string;
  buttonLabel?: string;
}

function SignInForm({
  onSignIn,
  callbackUrl,
  emailLabel,
  buttonLabel
}: SignInPageProps) {
  const {
    validationErrors,
    signInFormIsLoading,
    signInFormErrorMessage,
    registerFields,
    handleSignIn
  } = useSignInForm({
    onSignIn: (data) => onSignIn(data, { callbackUrl })
  });

  return (
    <Form onSubmit={handleSignIn}>
      <InputField
        id={AuthFields.EMAIL}
        name={AuthFields.EMAIL}
        label={emailLabel || 'Email address / Mobile Phone'}
        placeholder="Email Address"
        errorMessage={validationErrors.emailValidationError}
        ref={registerFields.registerEmail.ref}
        onChange={registerFields.registerEmail.onChange}
      />

      {/*<PasswordInputField*/}
      {/*  id={AuthFields.PASSWORD}*/}
      {/*  name={AuthFields.PASSWORD}*/}
      {/*  placeholder="• • • • • • • • • •"*/}
      {/*  label={*/}
      {/*    <>*/}
      {/*      Password*/}
      {/*      <Link to={AuthRoutes.forgotPassword()} ml="auto" tabIndex={-1}>*/}
      {/*        Forgot Password?*/}
      {/*      </Link>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  errorMessage={validationErrors.passwordValidationError}*/}
      {/*  ref={registerFields.registerPassword.ref}*/}
      {/*  onChange={registerFields.registerPassword.onChange}*/}
      {/*/>*/}

      <AlertMessage message={signInFormErrorMessage} />

      <PureSubmitButtonHelper
        isLoading={signInFormIsLoading}
        i18nText={buttonLabel || 'Sign In'}
      />
    </Form>
  );
}

export default SignInForm;
