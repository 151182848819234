import { Stack } from '@chakra-ui/layout';

import { useSignIn } from '../../hooks/useSignIn';

import { AuthenticationLayout } from '../../../layouts/AuthenticationLayout';
import { AuthenticationWrapper } from '../../components/wrappers/AuthenticationWrapper';

import { SignInButtons } from './components/SignInButtons';
import { SignInForm } from './components/SignInForm';

import { LoginMethodDivider } from './helpers/LoginMethodDivider';

import { AlertMessage } from '../../../helpers/AlertMessage';
import { useDisclosure } from '@chakra-ui/react';
import { PureButtonHelper } from '../../../helpers/buttons/PureButtonHelper';

interface SignInPageProps {
  // onSignIn: (afterSignInRoute?: string | null) => void; // user: { nanoId: UserNanoId; cityId: MayBeUserCityId } | null
  // onSignInWithMagicLink: () => void;
  onSignInSuccessful: (email: string) => void;
  callbackUrl?: string;
}

function SignInPage({ onSignInSuccessful, callbackUrl }: SignInPageProps) {
  const {
    signInSuccessMessage,
    signInWithEmail,
    signInWithGoogle,
    signInWithLinkedIn
  } = useSignIn(onSignInSuccessful);

  const { isOpen: isOpenSignInForm, onOpen: onOpenSignInForm } =
    useDisclosure();

  return (
    <AuthenticationLayout isSignInPage>
      <AuthenticationWrapper
      // title="Sign in to Nxmoov"
      // subTitle="Sign in or create an account to continue"
      >
        <Stack spacing={6}>
          <Stack spacing={6}>
            <SignInButtons
              onSignInWithGoogle={signInWithGoogle}
              onSignInWithLinkedIn={signInWithLinkedIn}
            />
            <LoginMethodDivider />
            {isOpenSignInForm ? (
              <>
                <SignInForm
                  onSignIn={signInWithEmail}
                  callbackUrl={callbackUrl}
                />
                <AlertMessage
                  textColor="green"
                  message={signInSuccessMessage}
                />
              </>
            ) : (
              <PureButtonHelper
                i18nText="Continue with email"
                onClick={onOpenSignInForm}
              />
            )}
          </Stack>

          {/*<SignUpLink />*/}
        </Stack>
      </AuthenticationWrapper>
    </AuthenticationLayout>
  );
}

export default SignInPage;
