import trim from 'lodash/trim';

import { isEmail } from '../../../../../../../utils/isEmail';

const emailRules = {
  required: 'Email is required',
  validate: {
    isValid: (value: string) => isEmail(trim(value)) || 'Email is invalid'
  }
};

const passwordRules = {
  required: 'Password is required'
};

function useSignInValidationRules() {
  return {
    emailRules,
    passwordRules
  };
}

export default useSignInValidationRules;
