import { Flex, Grid, HStack, Stack, StackDivider } from '@chakra-ui/layout';
import { SystemProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { AvatarHelper } from '../../helpers/AvatarHelper';
import { Heading } from '../../helpers/Heading';
import { Link } from '../../helpers/Link';
import { Logo } from '../../helpers/Logo';
import { Text } from '../../helpers/Text';

import { MayBeJobRecruiter } from '../../main/jobs/jobsTypes';

import {
  CompanyName,
  MayBeCompanyImage
} from '../../main/companies/companiesTypes';
import { Image } from '../../main/images/ImagesUrl';
import { HeaderOnSignOut } from '../AppLayout/components/Header/Header.types';
import { AnonymousUser } from '../../auth/hooks/useAuth/AnonymousUser';
import { AuthenticatedUser } from '../../auth/hooks/useAuth/AuthenticatedUser';
import { HeaderAccountDropdownMenu } from '../AppLayout/components/HeaderAccountDropdownMenu';

interface AuthenticationLayoutWithCurrentUser {
  onSignOut: HeaderOnSignOut;
  currentUser: AnonymousUser | AuthenticatedUser;
}

interface AuthenticationLayoutWithoutCurrentUser {
  onSignOut?: never;
  currentUser?: never;
}

type AuthenticationLayoutProps = {
  children?: ReactNode;
  isSignUpPage?: boolean;
  isSignInPage?: boolean;
  justifyContent?: SystemProps['justifyContent'];
  isJobResumeRecruiterPage?: boolean;
  jobRecruiter?: MayBeJobRecruiter;
  withPoweredByFooter?: boolean;
  company?: {
    image: MayBeCompanyImage;
    name: CompanyName;
  } | null;
} & (
  | AuthenticationLayoutWithCurrentUser
  | AuthenticationLayoutWithoutCurrentUser
);

function AuthenticationLayout({
  children,
  isSignUpPage,
  isSignInPage,
  justifyContent = 'center',
  isJobResumeRecruiterPage,
  jobRecruiter,
  withPoweredByFooter,
  company,
  currentUser,
  onSignOut
}: AuthenticationLayoutProps) {
  const title = () => {
    if (isSignInPage) return 'Welcome back';

    if (isSignUpPage) return 'Sign up and join the conversation';

    return 'The Best Recruiters Live Here';
  };

  const bodyText = () => {
    if (isSignInPage) return '';

    if (isSignUpPage)
      return 'Collaborate and give feedback on candidate profiles by signing up';

    return "We'll help you engage and hire the best talent available and build a network you'll be proud of!";
  };

  return (
    <Flex
      w="100vw"
      h="100vh"
      bg="#F7F7F7"
      alignItems="center"
      justifyContent="start"
      flexDirection="row"
    >
      <Stack
        w="100%"
        h="100%"
        display={{ base: 'none', xl: 'flex' }}
        justifyContent="center"
        alignItems="center"
        spacing={6}
        sx={{
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(/images/people-grid.jpg) no-repeat',
          backgroundSize: 'cover'
        }}
      >
        {isJobResumeRecruiterPage ? (
          <Flex
            width={300}
            height={300}
            direction="column"
            justifyContent="center"
            alignItems="center"
            bg="white"
            borderRadius="100%"
            position="relative"
          >
            <AvatarHelper
              image={jobRecruiter?.image as Image}
              name={jobRecruiter?.fullName || jobRecruiter?.name}
              size="2xl"
            />

            {jobRecruiter ? (
              <Flex direction="column" gap={0} position="absolute" bottom={7}>
                <Heading color="gray.900" level="h4" noOfLines={1}>
                  {jobRecruiter.fullName || jobRecruiter.name}
                </Heading>
                <Heading color="gray.500" level="h4" mt={-1}>
                  Recruiter
                </Heading>
              </Flex>
            ) : null}
          </Flex>
        ) : (
          <>
            <Heading
              level="h1"
              color="white"
              fontWeight={600}
              textAlign="center"
            >
              {title()}
            </Heading>
            <Heading
              level="h3"
              color="white"
              fontWeight={500}
              textAlign="center"
              maxW="560"
            >
              {bodyText()}
            </Heading>
          </>
        )}
      </Stack>

      <Grid
        gridTemplateRows={withPoweredByFooter ? '1fr max-content' : '1fr'}
        w="100%"
        h="100%"
      >
        <Stack
          pt={justifyContent === 'center' ? 6 : 12}
          pb={6}
          px={6}
          spacing={6}
          overflow="auto"
          alignItems="center"
          justifyContent={justifyContent}
          position="relative"
          bg={{ sm: 'white' }}
        >
          {currentUser ? (
            <HStack w="100%" justifyContent="flex-end">
              <HeaderAccountDropdownMenu
                currentUser={currentUser}
                onSignOut={onSignOut}
                showProfile={false}
                showSettings={false}
              />
            </HStack>
          ) : null}
          {isJobResumeRecruiterPage ? null : (
            <Stack alignItems="center" spacing={0}>
              {company?.image ? (
                <AvatarHelper
                  image={company.image}
                  name={company.name}
                  size="2xl"
                />
              ) : (
                <Flex w="100%" maxW="480" justifyContent="center">
                  <Logo />
                </Flex>
              )}
              <Heading level="h3">{company?.name}</Heading>
            </Stack>
          )}

          <Stack
            w="100%"
            maxW="480"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            spacing={10}
          >
            {children}
          </Stack>

          <Stack
            direction="row"
            justifyContent="center"
            spacing={3}
            divider={
              <StackDivider
                as="aside"
                bg="#C4C4C4"
                border="none"
                width={1}
                height={1}
                borderRadius={4}
                alignSelf="center"
              />
            }
          >
            <Link textStyle="body2Regular" to="" color="#9A9CA0">
              Privacy Policy
            </Link>

            <Link textStyle="body2Regular" to="" color="#9A9CA0">
              Account Policy
            </Link>
            <Link textStyle="body2Regular" to="" color="#9A9CA0">
              Terms of Sale
            </Link>
          </Stack>
        </Stack>

        {withPoweredByFooter ? (
          <Flex
            h={12}
            gap={2}
            w="100%"
            bg="#F7F7F9"
            marginTop="auto"
            alignItems="center"
            justifyContent="center"
            display={{ base: 'none', lg: 'flex' }}
            boxShadow="0px 0px 2px rgba(0, 0, 0, 0.25)"
          >
            <Flex alignItems="center">
              <Text color="gray.600" mt={-2.5}>
                Powered by
              </Text>
              <Logo h={8} w={20} />
            </Flex>
          </Flex>
        ) : null}
      </Grid>
    </Flex>
  );
}

export default AuthenticationLayout;
