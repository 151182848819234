import {
  useReactHookForm,
  ReactHookFormFieldError
} from '../../../../../../../common/hooks/base/useReactHookForm';

import { AuthFields, AuthEmail } from '../../../../../../authTypes';

import { useSignInValidationRules } from '../useSignInValidationRules';

import { OnSignInUserForm, SignInUserFormData } from '../../SignInForm.types';

const defaultSignInValues: SignInUserFormData = {
  email: '' as AuthEmail
  // password: '' as AuthPassword
};

interface SignInFormOptions {
  onSignIn: OnSignInUserForm;
}

function useSignInForm({ onSignIn }: SignInFormOptions) {
  const {
    control,
    errors,
    isLoading,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm
  } = useReactHookForm<SignInUserFormData>({
    defaultValues: defaultSignInValues
  });

  const { emailRules } = useSignInValidationRules();

  return {
    control,
    validationErrors: {
      emailValidationError: (errors.email as ReactHookFormFieldError)?.message
      // passwordValidationError: (errors.password as ReactHookFormFieldError)
      //   ?.message
    },
    signInFormIsLoading: isLoading,
    signInFormErrorMessage: errorMessage,
    resetSignInForm: resetForm,
    registerFields: {
      registerEmail: register(AuthFields.EMAIL, emailRules)
      // registerPassword: register(AuthFields.PASSWORD) // , passwordRules
    },
    handleSignIn: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onSignIn?.({
          email: data.email as AuthEmail
          // password: data.password as AuthPassword
        })
    })
  };
}

export default useSignInForm;
