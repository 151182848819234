import React from 'react';
import { Stack } from '@chakra-ui/layout';
import { Button } from '../../../../../helpers/Button';
import { GoogleIcon } from '../../../../../icons/GoogleIcon';
import { LinkedInIcon } from '../../../../../icons/LinkedInIcon';

interface SignInButtonsProps {
  // onSignInWithMagicLink: () => void;
  // onSignInWithMicrosoft: () => void;
  onSignInWithGoogle: () => void;
  onSignInWithLinkedIn: () => void;
}

function SignInButtons({
  // onSignInWithMagicLink,
  // onSignInWithMicrosoft,
  onSignInWithGoogle,
  onSignInWithLinkedIn
}: SignInButtonsProps) {
  return (
    <Stack as="section" spacing={2}>
      {/* <Button
        hierarchy="tertiary"
        border="1px solid #E0DFE5"
        color="gray.900"
        leftIcon={<Icon icon={IconsEnum.EYE} />}
        onClick={onSignInWithMagicLink}
      >
        Continue with Magic Link
      </Button> */}

      {/* <Button
        hierarchy="tertiary"
        border="1px solid #E0DFE5"
        color="gray.900"
        leftIcon={<MicrosoftIcon />}
        onClick={onSignInWithMicrosoft}
      >
        Continue with Microsoft
      </Button> */}

      <Button
        hierarchy="tertiary"
        border="1px solid #E0DFE5"
        color="gray.900"
        leftIcon={<GoogleIcon />}
        onClick={onSignInWithGoogle}
      >
        Continue with Google
      </Button>

      <Button
        hierarchy="tertiary"
        border="1px solid #E0DFE5"
        color="gray.900"
        leftIcon={<LinkedInIcon />}
        onClick={onSignInWithLinkedIn}
      >
        Continue with LinkedIn
      </Button>
    </Stack>
  );
}

export default SignInButtons;
